<template>
  <body ontouchstart>
    <div>
      <div style="display: block">
        <van-dropdown-menu style="display: block; text-align: center">
          <van-dropdown-item
            v-model="value1"
            :options="option1"
            @change="changeDept"
          />
        </van-dropdown-menu>
      </div>
      <div style="background-color: white">
        <van-swipe
          class="my-swipe"
          :loop="false"
          :width="60"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in classTime" :key="index">
            <div>
              <van-button
                class="btnclick"
                block
                @click="onclickTime(item.date)"
              >
                <div>
                  <div class="txtstyle" name="txt">{{ item.day }}</div>
                  <div plain class="btnstyle" name="btn">{{ item.tiem }}</div>
                </div>
              </van-button>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <van-list style="margin: 4% 0">
        <van-cell title="上午">
          <van-icon
            slot="icon"
            class="iconfont"
            class-prefix="icon"
            name="shuxian"
            color="#57c4b7"
          />
        </van-cell>
        <van-cell-group v-for="Ditem of lists" :key="Ditem + 'Docl'">
          <van-list v-if="Ditem.noonName == '上午'">
            <div
              style="width: 100%; height: auto"
              @click="
                onSelectDoc(
                  Ditem.regLevelCode,
                  Ditem.regLevelName,
                  Ditem.deptCode,
                  Ditem.deptName,
                  Ditem.doctorCode,
                  Ditem.doctorName,
                  Ditem.totFee,
                  Ditem.beginTime,
                  Ditem.endTime,
                  Ditem.regNum,
                  Ditem.noonId,
                  Ditem.schemaNO
                )
              "
            >
              <van-cell :label="Ditem.Show">
                <template slot="title">
                  <!-- <div v-if="Ditem.operatorId !== ''"> -->
                  <div>
                    <span style="font-size: 16px">
                      {{ Ditem.doctorName }}
                    </span>
                    <span style="width: 14px">&nbsp;</span>
                    <span style="color: #57c4b7; font-size: 14px">{{
                      Ditem.regLevelName
                    }}</span>
                    <span style="color: #57c4b7; float: right">{{
                      Ditem.deptName
                    }}</span>
                  </div>
                </template>
                <template slot="label">
                  <div>
                    剩余号源：{{ Ditem.regNum }}
                    <span style="font-size: 12px; color: #57c4b7; float: right"
                      >费用：{{ Ditem.totFee }}元</span
                    >
                  </div>
                  <div>
                    就诊时间：
                    <span style="font-size: 12px; color: #57c4b7"
                      >{{ Ditem.beginTime }}~{{ Ditem.endTime }}</span
                    >
                  </div>
                </template>
              </van-cell>
            </div>
          </van-list>
        </van-cell-group>
      </van-list>

      <van-list style="margin: 4% 0">
        <van-cell title="下午">
          <van-icon
            slot="icon"
            class="iconfont"
            class-prefix="icon"
            name="shuxian"
            color="#57c4b7"
          />
        </van-cell>
        <van-cell-group v-for="Ditem of lists" :key="Ditem + 'Docl'">
          <van-list v-if="Ditem.noonName == '下午'">
            <div
              style="width: 100%; height: auto"
              @click="
                onSelectDoc(
                  Ditem.regLevelCode,
                  Ditem.regLevelName,
                  Ditem.deptCode,
                  Ditem.deptName,
                  Ditem.doctorCode,
                  Ditem.doctorName,
                  Ditem.totFee,
                  Ditem.beginTime,
                  Ditem.endTime,
                  Ditem.regNum,
                  Ditem.noonId,
                  Ditem.schemaNO
                )
              "
            >
              <van-cell :label="Ditem.Show">
                <template slot="title">
                  <!-- <div v-if="Ditem.operatorId !== ''"> -->
                  <div>
                    <span style="font-size: 16px">
                      {{ Ditem.doctorName }}
                    </span>
                    <span style="width: 14px">&nbsp;</span>
                    <span style="color: #57c4b7; font-size: 14px">{{
                      Ditem.regLevelName
                    }}</span>
                    <span style="color: #57c4b7; float: right">{{
                      Ditem.deptName
                    }}</span>
                  </div>
                </template>
                <template slot="label">
                  <div>
                    剩余号源：{{ Ditem.regNum }}
                    <span style="font-size: 12px; color: #57c4b7; float: right"
                      >费用：{{ Ditem.totFee }}元</span
                    >
                  </div>
                  <div>
                    就诊时间：
                    <span style="font-size: 12px; color: #57c4b7"
                      >{{ Ditem.beginTime }}~{{ Ditem.endTime }}</span
                    >
                  </div>
                </template>
              </van-cell>
            </div>
          </van-list>
        </van-cell-group>
      </van-list>

      <!-- <van-list style="margin: 4% 0">
        <van-cell title="下午">
          <van-icon
            slot="icon"
            class="iconfont"
            class-prefix="icon"
            name="shuxian"
            color="#57c4b7"
          />
        </van-cell> -->

      <!-- <van-cell-group style="margin-top: 12px">
        <van-cell>
          <template slot="title">
            <div style="color: #57c4b7; font-size: 12px">温馨提示:</div>
          </template>
          <template slot="label">
            <div style="color: red">
              11:30-14:00以及17:30-8:00时段请到门诊收费室挂号并按下方提示位置进行就诊
            </div>
            <div>内科、儿科=》急诊科</div>
            <div>妇产科=》住院部5楼</div>
            <div>外一科=》住院部2楼</div>
            <div>外二科=》住院部1楼</div>
            <div>五官科、口腔科=》中彝医五官科住院部2楼</div>
            <div>中医科、皮肤科=》中彝医五官科住院部3楼</div>

          </template>
        </van-cell>
      </van-cell-group> -->
      <div
        style="
          font-size: 12px;
          color: #d3d3d3;
          margin: 1% 40%;
          width: auto;
          height: 40px;
        "
      >
        <span>&copy;拓实银医</span>
      </div>
    </div>
  </body>
</template>

<script>
import "@/assets/iconfont/shuxian/iconfont.css";
import ajax from "../../lib/ajax";
import { Toast, Dialog } from "vant";
import store from "../../store";
let nowDate = new Date();
var listss = [];
// let DepId = store.getters["store/depid"];
export default {
  data() {
    return {
      // dateTime: "",
      // dateTimes: "",
      // timeDate: "",
      // minDate: new Date(),
      // maxDate: new Date(nowDate.getTime() + 6 * 24 * 3600 * 1000),
      index: 0, //上午医生位数
      index1: 0, //下午医生位数
      index2: 0,
      index3: 0,
      lists: [],
      classTime: [],
      loading: false,
      finished: false,
      value1: 0,
      Today: "",
      DepId: store.getters["store/depid"],
      option1: [],
      show: false,
      currentDate: new Date(),
    };
  },
  mounted() {
    //   this.onConfirm(new Date());

    this.onload();
    this.getdeptData();
    this.value1 = this.DepId;
    // this.elClick();
  },
  updated() {
    this.elClick();
  },
  methods: {
    async onload() {
      //获取当前时间
      // console.log(DepId);
      Toast.loading({
        duration: 0,
        message: "获取数据中，请等待...",
        forbidClick: true,
        loadingType: "spinner",
      });
      var Date = {
        year: nowDate.getFullYear(),
        month:
          nowDate.getMonth() + 1 < 10
            ? "0" + (nowDate.getMonth() + 1)
            : "" + (nowDate.getMonth() + 1),
        date:
          nowDate.getDate() < 10
            ? "0" + nowDate.getDate()
            : "" + nowDate.getDate(),
      };
      this.Today = Date.year + "-" + Date.month + "-" + Date.date;
      await ajax
        .post("/Api/RegistrationService/GetRegSchedules", {
          deptCode: this.DepId,
          startDate: this.Today,
        })
        .then((res) => {
          let list = res.data.result.source;
          for (let i = 0; i < list.length; i++) {
            const element = list[i];
            list[i].date = element.schDate;
          }
          // this.deptName = res.data.result.schedules[0].deptName;
          listss = list;
          console.log(listss);
        })
        .catch((err) => {
          console.log("请求失败，数据不存在" + err);
          Toast("获取医生数据失败,请稍后重试");
        });

      // this.onConfirm(nowDate);
      console.log(listss);
      this.setTime();
    },
    async getdeptData() {
      await ajax
        .get("/Api/RegistrationService/GetRegDepts", {
          startTime: this.Today,
          machineName: "wechat",
        })
        .then((res) => {
          let Deplist = res.data.result.departments; //定义接收数组
          // console.log(Deplist);
          for (let i = 0; i < Deplist.length; i++) {
            const element = Deplist[i];
            let obj = {
              text: element.deptName,
              value: element.deptCode,
            };
            this.option1.push(obj);
          }
          // console.log(DepId);
        })
        .catch((err) => {
          console.log(err);
          Toast("加载失败，请稍后重试");
        });
    },
    // sortDocNumber(obj1, obj2) {
    //   // obj1.noonId = parseInt(obj1.noonId);
    //   // obj2.noonId = parseInt(obj2.noonId);
    //   // console.log(obj1.noonId - obj2.noonId);
    //   return obj1.scheduleDate - obj2.scheduleDate;
    // },
    // showPopup() {
    //   this.show = true;
    // },
    setTime() {
      let list = [];
      let timeList = [];
      console.log(listss);
      for (var i = 0; i < listss.length; i++) {
        if (list.indexOf(listss[i].date) == -1) {
          // console.log(listss[i].endTime);
          const date = new Date(listss[i].schDate);
          let days = date.getDay();
          switch (days) {
            case 1:
              days = "周一";
              break;
            case 2:
              days = "周二";
              break;
            case 3:
              days = "周三";
              break;
            case 4:
              days = "周四";
              break;
            case 5:
              days = "周五";
              break;
            case 6:
              days = "周六";
              break;
            case 0:
              days = "周日";
              break;
          }
          let obj = {
            day: days,
            tiem: date.getDate(),
            date: listss[i].schDate,
          };
          list.push(listss[i].date);
          timeList.push(obj);
        }
      }
      this.classTime = timeList;
    },
    elClick() {
      if (document.getElementsByClassName("btnclick")[0]) {
        let el = document.getElementsByClassName("btnclick")[0];
        // console.log(el);
        el.click();
        this.elClick = function () {};
        // console.log(this.elClick);
      }
    },
    onclickTime(date) {
      // console.log("执行了");
      let paEl = window.event.currentTarget;
      // console.log(date);
      let txtEl = paEl.getElementsByClassName("txtstyle")[0];
      txtEl.setAttribute("class", "txtAddStyle");
      let btnEl = paEl.getElementsByClassName("btnstyle")[0];
      btnEl.setAttribute("class", "btnAddStyle");
      paEl.setAttribute("disabled", true);
      let elm = paEl.parentNode.parentNode;
      var item = [];
      var p = elm.parentNode.children;
      for (var i = 0, pl = p.length; i < pl; i++) {
        if (p[i] !== elm) item.push(p[i]);
      }
      // console.log(item);
      for (let i = 0; i < item.length; i++) {
        const element = item[i];
        if (element.getElementsByClassName("txtAddStyle")[0]) {
          let txtEl = element.getElementsByClassName("txtAddStyle")[0];
          txtEl.setAttribute("class", "txtstyle");
          let btnEl = element.getElementsByClassName("btnAddStyle")[0];
          btnEl.setAttribute("class", "btnstyle");
        }
        let btnelm = element.getElementsByClassName("van-button")[0];
        btnelm.removeAttribute("disabled");
      }
      this.getDocData(date);
    },
    getDocData(date) {
      this.index = 0;
      this.index1 = 0;
      this.index2 = 0;
      this.index3 = 0;
      this.lists = [];
      for (let i = 0; i < listss.length; i++) {
        const element = listss[i];
        console.log(date);
        console.log(date === element.schDate);
        if (date === element.schDate) {
          // console.log(element.items[0].endTime.substring(0, 10));
          // console.log(date);
          let nowDate = new Date().getTime();
          let date = element.endTime;

          let dateTime = new Date(date).getTime();
          console.log(nowDate - dateTime);
          if (nowDate - dateTime <= 0) {
            this.lists.push(element);
          }
          console.log(this.lists);
        }
      }
      // this.lists.sort(this.sortDocNumber);
      // console.log("执行了");
      // console.log(this.lists);
      for (let i = 0; i < this.lists.length; i++) {
        const element = this.lists[i];
        if (element.noonName == "上午") {
          this.index++;
        } else if (element.noonName == "下午") {
          this.index1++;
        } else if (element.noonId == 3) {
          this.index2++;
        } else if (element.noonId == 4) {
          this.index3++;
        }
      }
      Toast.clear();
    },
    // sortDocNumber(obj1, obj2) {
    //   obj1.noonId = parseInt(obj1.noonId);
    //   obj2.noonId = parseInt(obj2.noonId);
    //   // console.log(obj1.noonId - obj2.noonId);
    //   return obj1.noonId - obj2.noonId;
    // },
    async changeDept() {
      this.DepId = this.value1;
      store.commit("store/setDepid", this.value1);
      this.onload().then(() => {
        this.onclickDept();
      });
    },
    async onclickDept() {
      let el = document.getElementsByClassName("btnclick")[0];
      if (el.getElementsByClassName("txtAddStyle")[0]) {
        let txtEl = el.getElementsByClassName("txtAddStyle")[0];
        txtEl.setAttribute("class", "txtstyle");
        let btnEl = el.getElementsByClassName("btnAddStyle")[0];
        btnEl.setAttribute("class", "btnstyle");
      }
      el.removeAttribute("disabled");
      console.log(el);
      await el.click();
    },

    async onSelectDoc(
      regLevelId,
      regLevelName,
      operatorId,
      deptName,
      doctCode,
      operatorName,
      totalFee,
      beginTime,
      endTime,
      rmngNum,
      noonId,
      schemaNO
    ) {
      // alert(rmngNum);
      if (rmngNum <= 0) {
        Dialog.alert({
          title: "温馨提示",
          message: "该医生号源已被约满，请选择其他医生！",
        }).then(() => {});
      } else {
        console.log("诊疗级别编码" + regLevelId);
        console.log("诊疗级别名称：" + regLevelName);
        console.log("医生或科室编码" + operatorId);
        console.log("排班日期" + endTime.substring(0, 10));
        console.log("开始时间" + beginTime);
        console.log("结束时间" + endTime);
        // console.log("号源编码" + seeNo);
        console.log("科室名称" + deptName);
        console.log("医生姓名" + doctCode);
        console.log("总需要支付费用" + totalFee);
        console.log("排班流水号" + schemaNO);
        // console.log("排班流水号" + schemaId);
        store.commit("store/setRegLevel", regLevelId);
        store.commit("store/setRegLevelName", regLevelName);
        store.commit("store/setDorid", operatorId);
        store.commit("store/setDoctCode", doctCode);
        store.commit("store/setSeeTime", endTime);
        store.commit("store/setBeginTime", beginTime);
        store.commit("store/setEndTime", endTime);
        // store.commit("store/setSeeNo", seeNo);
        store.commit("store/setDepname", deptName);
        store.commit("store/setDorname", operatorName);
        store.commit("store/setTotalFee", totalFee);
        store.commit("store/setNoonId", noonId);
        store.commit("store/setSchemaNO", schemaNO);
        // store.commit("store/setSchemaId", schemaId);
        var patientid = store.getters["store/userId"]; //声明病历号
        console.log(patientid);
        this.$router
          .replace({
            path: "/IQueryInfo",
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style  scoped>
.txtstyle {
  font-size: 12px;
  line-height: 24px;
  margin-left: 6px;
}
.txtAddStyle {
  font-size: 12px;
  line-height: 24px;
  color: #57c4b7;
  margin-left: 6px;
}
.btnstyle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.btnAddStyle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #57c4b7;
  color: white;
}
.van-button--block {
  display: block;
  width: 100%;
  height: auto;
  border: none;
}
.my-swipe .van-swipe-item {
  /* color: #fff; */
  font-size: 20px;
  line-height: 50px;
  text-align: center;
}
</style>
